import React, { Component } from "react"
import { Slide, Fade } from "react-slideshow-image"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import "../scss/iris.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import IrisTbImg from "../components/iris-tb-img"
import SolLogo from "../components/solution-logo"
import { getVisions } from "../data/home"
import {
  navbarUpdate,
  setDefaultHeaderTheme,
  getIrisTbContents,
  getIrisFeatures,
} from "../utils/common-util"

var scroll = 0

const videoWHRatio = 1.777

class IrisPage extends Component {
  constructor(props) {
    super(props)

    this.featureVideos = {}
    this.trustedByImgRef = React.createRef()

    this.trustedBySliderProps = {
      duration: 10000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: false,
      onChange: this.onTrustedBySlideChange.bind(this),
    }

    this.trustedByFadeProp = {
      transitionDuration: 400,
      infinite: false,
      indicators: false,
      autoplay: false,
      arrows: false,
    }

    this.state = {
      currentScroll: 0,
    }

    this.onPageScroll = this.onPageScroll.bind(this)
    this.getVisionRow = this.getVisionRow.bind(this)
  }

  onTrustedBySlideChange = (oldIndex, newIndex) => {
    this.trustedByImgRef.current.goTo(newIndex)
  }

  onPageScroll = e => {
    let diff = window.scrollY - this.state.currentScroll
    scroll += diff
    if (scroll > 100) {
      scroll = 0
      for (const key in this.featureVideos) {
        if (this.featureVideos.hasOwnProperty(key) && this.featureVideos[key]) {
          const $video = this.featureVideos[key].firstElementChild
          const bounding = $video.getBoundingClientRect()
          if (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.right <=
              (window.innerWidth || document.documentElement.clientWidth) &&
            bounding.bottom <=
              (window.innerHeight || document.documentElement.clientHeight)
          ) {
            $video.paused && $video.play()
          } else if (!$video.paused) {
            $video.pause()
          }
        }
      }
    }
  }

  getVisionRow = vs => {
    return (
      <div
        id={vs.key + "-row"}
        key={vs.key}
        className={"mf-row" + (vs.swipe ? " mf-swipe" : "")}
      >
        <div className="mf-info">
          <div className="mf-title">{vs.label}</div>
          <div className="mf-desc">{vs.detail}</div>
        </div>
        <div className="mf-gap"></div>
        <div
          className="mf-video"
          ref={r => (this.featureVideos[vs.key] = vs.video ? r : null)}
        >
          <video
            loop
            preload="auto"
            autoPlay
            muted
            crossOrigin="anonymous"
            poster={vs.poster}
          >
            {vs.video ? <source src={vs.video} type="video/mp4" /> : null}
            Your browser does not support the video tag...
          </video>
        </div>
      </div>
    )
  }

  componentDidMount() {
    setDefaultHeaderTheme(true)
    if (this.props.location.state && this.props.location.state.section) {
      let $html = document.getElementsByTagName("HTML")[0]
      setTimeout(() => {
        if (!$html.classList.contains("scroll-smooth")) {
          $html.classList.add("scroll-smooth")
        }
        document
          .getElementById(this.props.location.state.section)
          .scrollIntoView({ block: "center" })
        setTimeout(() => {
          $html.classList.remove("scroll-smooth")
        }, 300)
      }, 200)
    }
    this.setState({ currentScroll: window.scrollY })
    navbarUpdate("nav-products")
    for (const key in this.featureVideos) {
      if (this.featureVideos.hasOwnProperty(key) && this.featureVideos[key]) {
        const $wrap = this.featureVideos[key]
        let height = $wrap.clientWidth / videoWHRatio
        $wrap.firstElementChild.style.height = height - 1 + "px"
      }
    }
    window.addEventListener("scroll", this.onPageScroll, false)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onPageScroll, false)
  }

  render() {
    const visions = getVisions()

    const features = getIrisFeatures()

    const tbContents = getIrisTbContents()

    const Dashboard = () => {
      const data = useStaticQuery(graphql`
        query {
          image: file(relativePath: { eq: "iris-dashboard.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1676) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `)
      return (
        <div className="abs-content">
          <Img
            fluid={data.image.childImageSharp.fluid}
            alt="Dummy dashboard"
            imgStyle={{ objectFit: "contain" }}
          />
        </div>
      )
    }

    return (
      <Layout>
        <SEO title="IRIS" />
        <div className="iris-top-content iww-full-height iww-container responsive">
          <div className="sol-icon">
            <SolLogo name="iris" alt="IRIS's Logo"></SolLogo>
          </div>
          <h1 className="top-heading">Enterprise Computer Vision Solution</h1>
          <Dashboard></Dashboard>
        </div>
        <div className="iris-feature-content iww-container">
          <div className="fea-heading">Observe. Infer. Act.</div>
          <div className="fea-main-desc">
            Get your CCTV Cameras to do more through real-time AI.
          </div>
          <div className="features">
            {features.map(fea => {
              return (
                <div key={fea.key} className="feature">
                  <div className={"fea-icon " + fea.icon}></div>
                  <div className="fea-title">{fea.label}</div>
                  <div className="fea-desc">{fea.desc}</div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="major-feature-content iww-container">
          <div className="mf-heading">
            Discover Measurable Business Benefits
          </div>
          <div className="mf-main-desc">
            Utilises deep learning models to learn, detect, categorise, and
            analyse the video feed. It can be trained on our models or even
            clients’ own unique data. Once this is done, it can automatically
            detect objects and analyse the video to apply the right
            configurations and process a response in under a second.
          </div>
          {visions.map(this.getVisionRow)}
        </div>
        <div className="iris-trusted-by-content">
          <div className="trusted-by-imgs">
            <Fade {...this.trustedByFadeProp} ref={this.trustedByImgRef}>
              {tbContents.map(tb => {
                return (
                  <div key={tb.key + "-img"} className="each-fade">
                    <IrisTbImg name={tb.key}></IrisTbImg>
                  </div>
                )
              })}
            </Fade>
          </div>
          <div className="trusted-by-slides">
            <div className="trusted-by-title">
              <span className="left-line"></span>
              <span>Trusted By</span>
            </div>
            <Slide {...this.trustedBySliderProps}>
              {tbContents.map(tb => {
                return (
                  <div key={tb.key + "-cnt"} className="each-slide">
                    <div className="trusted-by-head">{tb.label}</div>
                    <div className="trusted-by-text">{tb.desc}</div>
                  </div>
                )
              })}
            </Slide>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IrisPage
