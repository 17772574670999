import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const IrisTbImg = ({ name, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      tb00001: file(relativePath: { eq: "trusted-by-iris/tb00001.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1094) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tb00002: file(relativePath: { eq: "trusted-by-iris/tb00002.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tb00003: file(relativePath: { eq: "trusted-by-iris/tb00003.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tb00006: file(relativePath: { eq: "trusted-by-iris/tb00006.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1096) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tb00007: file(relativePath: { eq: "trusted-by-iris/tb00007.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1038) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tb00008: file(relativePath: { eq: "trusted-by-iris/tb00008.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 694) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data[name] ? (
    <Img
      fluid={data[name].childImageSharp.fluid}
      alt={alt || ""}
      imgStyle={{ objectFit: "contain" }}
    />
  ) : null
}

export default IrisTbImg
